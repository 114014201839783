import styled from 'styled-components';
import spacing from 'styles/spacing';
import screenSize from 'styles/mediaQueries';

export const Wrapper = styled.div`
  display: grid;
  grid-gap: ${spacing.sm};
  width: 100%;

  grid-template-columns: repeat(2, minmax(0, 1fr));

  ${screenSize.minDesktop`
    grid-template-columns: repeat(2, minmax(12.5rem, 1fr));
  `}

  ${screenSize.minLargeDesktop`
    grid-template-columns: repeat(3, minmax(12.5rem, 1fr));
  `}
`;
