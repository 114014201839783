import styled from "styled-components";

import React from "react";
import screenSize from "styles/mediaQueries";
import * as Styled from "./ContentGrid.styled";

export default function ContentGrid(props: any) {
  const { children, ...rest } = props;
  return <Styled.Wrapper {...rest}>{children}</Styled.Wrapper>;
}

export const Feature = styled.div`
  grid-column: span 2;
  grid-row: span 2;
`;

export const PromotedHorizontal = styled.div`
  grid-row: span 1;
  grid-column: span 2;
`;

export const PromotedVertical = styled.div`
  grid-row: span 2;
  grid-column: span 2;

  ${screenSize.minTablet`
    grid-column: span 1;
  `}
`;

export const RegularItem = styled.div`
  display: flex;
  grid-column: span 2;

  ${screenSize.minTablet`
    grid-column: span 1;
  `}
`;
