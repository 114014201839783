import styled from 'styled-components';

export const Container = styled.div`
  transition: transform 150ms;
  border-radius: 4px;
  box-shadow: 4px 4px 12px 2px rgb(0 0 0 / 30%);
  background-color: var(--color-lightShades);

  ${props =>
    props.isHovering
      ? `
        z-index: 1;
        box-shadow: 4px 4px 12px 6px rgba(0, 0, 0, 0.3);
    `
      : `
        z-index: 0;
    `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: linear-gradient(
    to top right,
    rgba(219, 68, 55, 1),
    rgba(244, 160, 0, 0.8)
  );
`;
