import axios from "axios";
import qs from "query-string";
import getConfig from "../config";
import { v4 as uuidv4 } from 'uuid';

const config = getConfig();

const apiHostname = config.api.hostname;
const PRODUCT_PRICE = "atm-api/v1/storyblok/request";

export async function storyblokRequest(
  resourcePath: string,
  params: any,

): Promise<any> {
  const requestParams = qs.stringify({ resourcePath, ...params });

  const reqID = uuidv4();
  const result = await axios.get(`${apiHostname}/${PRODUCT_PRICE}?${requestParams}`, { headers: { reqID} });
  if (!result.data) {
    console.log(reqID, result);
  }
  return result.data;
}
