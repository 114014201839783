import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import * as Styled from './TiltCard.styled';

const X_ROTATION_FACTOR = 0.05;
const Y_ROTATION_FACTOR = -0.05;
const HOVER_SCALE_FACTOR = 1.05;

const INITIAL_X_ROTATION = 0;
const INITIAL_Y_ROTATION = 0;
const INITIAL_SCALE_FACTOR = 1;

export default function TiltCard(props) {
  const {
    children,
    xRotationFactor,
    yRotationFactor,
    hoverScaleFactor,
  } = props;
  const [xRotation, setXRotation] = React.useState(INITIAL_X_ROTATION);
  const [yRotation, setYRotation] = React.useState(INITIAL_Y_ROTATION);
  const [scaleFactor, setScaleFactor] = React.useState(INITIAL_SCALE_FACTOR);
  const [isHovering, setIsHovering] = React.useState(false);

  const containerRef = React.useRef();

  const reset = () => {
    setXRotation(INITIAL_X_ROTATION);
    setYRotation(INITIAL_Y_ROTATION);
    setScaleFactor(INITIAL_SCALE_FACTOR);
    setIsHovering(false);
  };

  const handleMouseMove = e => {
    e.preventDefault();
    if (containerRef && containerRef.current) {
      const boundingRect = containerRef.current.getBoundingClientRect();

      // x distance from center
      const rawXOffset = e.clientX - (boundingRect.x + boundingRect.width / 2);
      const rawYOffset = e.clientY - (boundingRect.y + boundingRect.height / 2);

      setXRotation(rawYOffset * xRotationFactor);
      setYRotation(rawXOffset * yRotationFactor);
      setScaleFactor(hoverScaleFactor);
      setIsHovering(true);
    }
  };

  const handleMouseLeave = () => {
    reset();
  };

  const throttledMouseMove = React.useRef(
    throttle(handleMouseMove, 75, { trailing: false })
  );

  return (
    <Styled.Container
      ref={containerRef}
      onMouseMove={throttledMouseMove.current}
      onMouseLeave={handleMouseLeave}
      onClick={reset}
      isHovering={isHovering}
      style={{
        transform: `perspective(50em) rotateX(${xRotation}deg) rotateY(${yRotation}deg)  scale(${scaleFactor})`,
      }}
    >
      {/* <Styled.ImageContainer /> */}
      {children}
    </Styled.Container>
  );
}

TiltCard.defaultProps = {
  xRotationFactor: X_ROTATION_FACTOR,
  yRotationFactor: Y_ROTATION_FACTOR,
  hoverScaleFactor: HOVER_SCALE_FACTOR,
};

TiltCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  xRotationFactor: PropTypes.number,
  yRotationFactor: PropTypes.number,
  hoverScaleFactor: PropTypes.number,
};
