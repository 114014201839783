import React from "react";
import Link from "next/link";
import PropTypes, { string } from "prop-types";
import placeholderImage from "../../../../public/images/placeholder.png";
import { TextSmallP } from "styles/typography";
import { ThemeContext } from "components/hocs/ThemeProvider";
import colours from "styles/colours";
import { maxWidthStoryblokImage } from "utils/storyblok";
import { storyblokLoader } from 'utils/storyblok';
import * as Styled from "./ArticleCard.styled";

function ArticleCard(props) {
  const {
    image,
    previewImage,
    imageAlt,
    heading,
    link,
    shortDescription,
    categories,
    blogSeries,
    hideDescription,
  } = props;

  const { colorMode } = React.useContext(ThemeContext);

  return (
    <Link href={link} passHref legacyBehavior>
      <Styled.Card>
        <Styled.Top>
          <Styled.ImageWrapper>
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <Styled.PreviewImage
                src={previewImage ? maxWidthStoryblokImage(previewImage, '600') : image.src}
                alt={imageAlt}
                layout="fill"
                loader={storyblokLoader}
              />
            </div>
          </Styled.ImageWrapper>
          {blogSeries && (
            <Styled.SeriesContainer>
              <Styled.Category isAlignedRight>
                {blogSeries.name}
              </Styled.Category>
            </Styled.SeriesContainer>
          )}
        </Styled.Top>
        <Styled.Bottom>
          {categories && categories.length ? (
            <Styled.Categories>
              {categories.map((category) => {
                const backgroundColour = colorMode
                  ? colours[colorMode].colours[category.colour]
                  : null;
                const textColour = colorMode
                  ? colours[colorMode].contrasts[category.colour]
                  : null;
                return (
                  <Styled.Category
                    key={category.name}
                    textColour={textColour}
                    backgroundColour={backgroundColour}
                  >
                    {category.name}
                  </Styled.Category>
                );
              })}
            </Styled.Categories>
          ) : null}
          {heading && (
            <Styled.Heading noMarginBottom={hideDescription}>
              {heading}
            </Styled.Heading>
          )}
          {shortDescription && !hideDescription && (
            <TextSmallP>{shortDescription}</TextSmallP>
          )}
        </Styled.Bottom>
      </Styled.Card>
    </Link>
  );
}
export default ArticleCard;

ArticleCard.defaultProps = {
  hideDescription: false,
  image: placeholderImage,
  imageAlt: "",
  heading: "",
  shortDescription: "",
  blogSeries: null,
  categories: null,
};

ArticleCard.propTypes = {
  previewImage: string,
  hideDescription: PropTypes.bool,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  heading: PropTypes.string,
  shortDescription: PropTypes.string,
  link: PropTypes.string,
  categories: PropTypes.array,
  blogSeries: PropTypes.shape({
    name: PropTypes.string,
  }),
};
