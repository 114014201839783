import axios from 'axios';
import getConfig from '../config';

export * from './email';
export * from "./storyblok";

const config = getConfig();

const apiHostname = config.api.hostname;

const VAPID_KEY_ENDPOINT = 'atm-api/v1/push-notifications/vapid-keys';
const GET_SIGNED_STORYBLOK_URL_ENDPOINT =
  'atm-api/v1/storyblok/signed-storyblok-url?';

// eslint-disable-next-line import/prefer-default-export
export async function fetchNewVapidKeys() {
  return axios.get(`${apiHostname}/${VAPID_KEY_ENDPOINT}`);
}

export async function getSignedStoryblokURL(filename) {
  if (!filename) throw new Error('Must provide a filename.');
  return axios.post(
    `${apiHostname}/${GET_SIGNED_STORYBLOK_URL_ENDPOINT}?filname=${filename}`,
  );
}

export async function uploadUsingSignedURL(
  signedURL,
  formData,
  onUploadProgress
) {
  return axios.post(signedURL, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}
