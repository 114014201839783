import React from "react";
import { SectionOptions } from 'components/ui/SectionWrapper';
import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import { ArticlePageContent, PageConfig, Section } from "types/attheminute";
import ArticleCard from "components/ui/ArticleCard";
import TiltCard from "components/ui/TiltCard";
import { TextH2 } from "styles/typography";
import Link from "next/link";
import { PaddedContainer } from "components/ui/Layout";

import * as Styled from "./ArticleGrid.styled";

export interface ArticleGridSectionProps extends Section {
  heading?: string;
  showSeeMore?: boolean;
  articles: PageConfig<ArticlePageContent>[];
  dataTestId?: string;
}

function ArticleGrid(props: ArticleGridSectionProps) {
  const {
    dataTestId,
    heading: articleGridHeading,
    sectionOptions,
    articles,
    showSeeMore,
  } = props;

  return (
    <SectionWrapper
      data-testid={dataTestId}
      {...getSectionWrapperProps(sectionOptions)}
    >
      <TextH2>{articleGridHeading}</TextH2>
      <Styled.ArticleGrid>
        {articles &&
          articles.map((article) => (
            <TiltCard key={article.id}>
              <ArticleCard
                key={article.id}
                link={`/${article.fullSlug}/`}
                heading={article.content?.heading || ""}
                shortDescription={
                  article.content?.shortDescription ||
                  article.content?.layout?.articleHeader?.subheading ||
                  ""
                }
                previewImage={article.content?.previewImage}
                imageAlt={article.content?.previewImageAlt}
                categories={article.content?.categories}
                blogSeries={article.content?.blogSeries}
              />
            </TiltCard>
          ))}
      </Styled.ArticleGrid>
      {showSeeMore && (
        <PaddedContainer>
          <Styled.Content>
            <Link href="/blog" passHref>

              <Styled.SeeMore>See more...</Styled.SeeMore>

            </Link>
          </Styled.Content>
        </PaddedContainer>
      )}
    </SectionWrapper>
  );
}

export default ArticleGrid;
