import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { Content as DefaultContent } from "components/ui/Layout";
import spacing from "styles/spacing";
import { TextP, fontFamilyBrand } from "styles/typography";

export const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.875rem;

  ${screenSize.minTablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${screenSize.minDesktop`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const Content = styled(DefaultContent)`
  display: flex;
  justify-content: center;
  padding-bottom: ${spacing.md};
`;

export const SeeMore = styled(TextP)`
  font-family: ${fontFamilyBrand};
`;
