import styled from 'styled-components';
import { TextSmallP, TextH4 } from 'styles/typography';
import spacing from 'styles/spacing';
import screenSize from 'styles/mediaQueries';
import NextImage from "next/legacy/image";

export const Card = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;
  background: var(--color-lightShades);
  width: 100%;
  border-radius: 4px;
`;

export const Top = styled.figure`
  position: relative;
  width: 100%;
  padding-top: 60%;
  margin: 0px;
  overflow: hidden;
`;

export const Bottom = styled.div`
  position: relative;
  padding: ${spacing.sm};
  display: flex;
  flex-direction: column;
  align-items: start;

  margin: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${screenSize.minDesktop`
    padding: ${spacing.md};
  `}
`;

export const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 500ms ease 0s;
`;

export const PreviewImage = styled(NextImage)`
  object-fit: cover;
`;

export const Heading = styled(TextH4)`
  margin-top: 0;
`;

export const SeriesContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Categories = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%) translateZ(0);
`;

export const Category = styled(TextSmallP)`
  background: ${props => props.backgroundColour || 'var(--color-background)'};
  color: ${props => props.textColour || 'var(--color-text)'};
  display: flex;
  border-radius: ${props =>
    props.isAlignedRight ? '0 0 0 10px' : '0 0 10px 0'};
  justify-content: center;
  align-items: center;
  padding: 0 ${spacing.sm};
  :not(:last-child) {
    margin-bottom: ${spacing.xs};
  }
  display: inline-block;
`;
